
export const LANGUAGES = {
    EN: 'en',
    RO: 'ro'
}

export const PRISMIC_LANGUAGES = {
    'en': 'en-gb',
    'ro': 'ro-ro',
}
