<template>
  <div class="category-navigation">
    <div
        class="inner-category-navigation"
        :class="{'fixed-top': isMenuFixed }">
      <div class="links" @scroll="disableScrollSnap">
        <router-link class="link"
                     :ref="category.id"
                     :class = "{active: isActive(category.id)}"
                     v-for="(category, index) in items" :key="index" :to="`#${category.id}`"
                    > {{ category.name }}
        </router-link>
      </div>
      <div class="hover-background"></div>
    </div>
    <div class="hover-background"></div>
  </div>
</template>

<script>
import throttle from 'lodash/throttle';

export default {
  name: 'MenuCategoryComponent',
  props: {
    items: {
      type: Array,
      required: true,
    },
    activeItem: {
      type: String,
      required: false,
    }
  },
  data() {
    return {
      initialTop: 0,
      isMenuFixed: false,
    }
  },
  computed: {
    categoriesMenu() {
      return document.querySelector(".inner-category-navigation");
    },
    navStaticContainer() {
      return document.querySelector(".category-navigation");
    }
  },
  watch: {
    $route(to, from) {
      console.log('change route');
      if (to.hash) {
        this.navigateTo(to.hash);

        const navigationItem = document.querySelector('.link.active');
        const parent = navigationItem?.parentElement;
        console.log(navigationItem.offsetLeft);
        if (navigationItem && parent) {
          if(navigationItem.offsetLeft < navigationItem.innerWidth) {
            parent.scrollTo({
              left: 0,
              behavior: "smooth"
            })
            return;
          }
          console.log('wee', navigationItem.offsetLeft - navigationItem.clientWidth / 2);
          parent.scrollTo({
            left: navigationItem.offsetLeft - navigationItem.clientWidth / 2,
            behavior: "smooth"
          })
        }
      }
    },
    activeItem(to, from) {
      if (!this.$refs[to][0].$el) {
        return;
      }

      console.log( this.$refs[to][0].$el);
      this.$refs[to][0].$el.scrollIntoView();
    }
  },
  observer: null,
  methods: {
    disableScrollSnap() {
      const links = document.querySelectorAll('.link');
      links.forEach(link => {
        link.style.scrollSnapAlign = 'none';
      })
    },
    enableScrollSnap() {
      const links = document.querySelectorAll('.link.active');
      links.forEach(link => {
        link.style.scrollSnapAlign = 'center';
      })
    },
    createIntersectionObserver() {
      // const options = {
      //   root: null,
      //   rootMargin: '0px',
      //   threshold: 0.5
      // };
      // this.observer = new IntersectionObserver(this.handleIntersection, options);
    },
    handleIntersection(entries) {
      console.log(entries)
    },
    isActive(id) {
      return this.activeItem === id;
    },
    navigateTo(id) {
      const element = document.querySelector(id);
      if (element) {
        const upperOffset = 105;
        const elementPosition = element.offsetTop;
        const offsetPosition = elementPosition - upperOffset;
        window.scrollTo({
          top: offsetPosition,
          behavior: "smooth"
        });
      }
    },

    setFixedTop: throttle(function () {
      const distanceFromTop = this.categoriesMenu.getBoundingClientRect().top;
      const navStaticContainerTop = this.navStaticContainer.getBoundingClientRect().top;

      if(distanceFromTop < 50 && !this.isMenuFixed) {
        this.isMenuFixed = true;
      }

      if(navStaticContainerTop > 50 && this.isMenuFixed) {
        this.isMenuFixed = false;
      }


      //enable scroll snap
      this.enableScrollSnap();

    }, 50)
  },
  mounted() {
    this.initialTop = this.$el.getBoundingClientRect().top;
    document.addEventListener("scroll", this.setFixedTop);
  },
  beforeDestroy() {
    document.removeEventListener('scroll', this.setFixedTop);
    this.observer?.disconnect();
  }
}
</script>

<style lang="scss">
.category-navigation {
  background: #575855;
  font-family: 'Roboto', serif;
  white-space: nowrap;
  height: 50px;
  overflow: hidden;

  .inner-category-navigation {
    background: #575855;
    font-family: 'Roboto', serif;
    padding: 14px 0 10px 8px;
    position: relative;
    ::-webkit-scrollbar {
      display: none;
    }

    > ::after {
      content: '';
      padding-right: 30px;
    }

    .links {
      overflow-y: hidden;
      overflow-x: auto;
      white-space: nowrap;
      scroll-behavior: smooth;
      scroll-snap-type: x proximity;
    }

    > .hover-background {
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      width: 40px;
      background: linear-gradient(270deg, rgba(0,0,0, 0.65) 0%, rgba(0,0,0, .25) 75%, rgba(55,55,55, 0) 100%);
      transition: opacity 0.3s ease-in-out;
    }
  }

  .inner-category-navigation.fixed-top {
    background: #575855;
    position: fixed;
    top: 46px;
    left: 0;
    right: 0;
    z-index: 100;
  }

  .link {
    display: inline-block;
    line-height: 24px;
    height: 24px;
    padding: 0 8px;
    border: 1px solid #FFFFFF;
    border-radius: 40px;
    color: #ffffff;
    margin: 0 4px;
    text-decoration: none;
    font-weight: 400;

    &.active {
      background: #90D0AE;
      color: #3A4841;
      border: 1px solid #3A4841;
    }
  }
}
</style>
