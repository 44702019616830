<template>
  <div class="category" :id="item.id">
    <img v-if="item.imageUrl" :src="item.imageUrl" :alt="item.name">
    <h2 ref="title">{{ item.name }}</h2>
    <div class="products">
      <div class="product" v-for="item in products" :key="item.id">
        <p class="product__title">{{item.name}}{{item.quantity && ` - ${item.quantity}${item.quantityUnit}`}}</p>
        <span class="spacer"/>
        <p class="product__price">{{`${item.price} ${item.currency}`}}</p>
        <p v-if="item.description" class="product__description">{{item.description}}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Category',
  props: {
   item: {
     type: Object,
     required: true,
   }
  },
  methods: {
    //when in view update the navigation menu
    handleIntersection(entries) {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          this.$emit('updateActiveMenu', this.item.id)
        }
      });
    },
    createIntersectionObserver() {
      const options = {
        root: null,
        rootMargin: '0px',
        threshold: 1.0
      };
      this.observer = new IntersectionObserver(this.handleIntersection, options);
    },
  },
  mounted() {
    this.createIntersectionObserver();
    this.observer.observe(this.$refs.title);
  },
  beforeDestroy() {
    this.observer?.disconnect();
  },
  computed: {
    products: function () {
      return this.item.products;
    }
  }
}
</script>


<style lang="scss">
.category {
   margin: 18px 0;
    padding: 12px 0;

  img {
    width: 45%;
    margin: 0 auto;
    display: inherit;
  }

  h2 {
    font-family: 'LuckoftheIrish', serif;
    display: inline;
    font-style: normal;
    font-size: 32px;
    line-height: 40px;
    padding: 0;
    margin: -15% 0 0 0;
  }
  .products {
    text-align: left;
   .product {
     padding: 8px 12px;
     font-family: 'MrsEaves';
     font-style: italic;

     .product__title {
       font-style: italic;
       font-weight: bold;
       font-size: 29px;
       line-height: 32px;
       padding: 4px 0;
       margin: 0;
     }
     .spacer {
       display: block;
       height: 1px;
       background: #000000;
       width: 25%;
     }
     .product__price {
       font-weight: 500;
       font-size: 25px;
       line-height: 23px;
       margin: 0;
       padding: 8px 0;
     }

     .product__description {
       margin: 0;
       padding-top: 4px;
       font-weight: bold;
       font-size: 16px;
       line-height: 20px;
     }
   }


  }

}
</style>
