import Vue from 'vue'
import App from './App.vue'
import PrismicVue from '@prismicio/vue'
import router from './router'

Vue.config.productionTip = false

const endpoint = 'https://opeters-pub.prismic.io/api/v2'
Vue.use(PrismicVue, {
  endpoint
});

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
