import Menu from "../views/MenuView";

export default [
    {
        name: 'menu',
        path: '/:language/:type',
        component: Menu,
        props: true
    },
    {
        path: '*',
        redirect: '/en/food'
    }
];
