<template>

  <div class="menu">
    <RouterLink class="item" v-for="(menu, index) in menus" :to="`/${activeLanguage}/${menu.uid}`" active-class="active"
                :key="index" onclick="$emit('scrollToItem', menu.id)">
      <span>{{ menu.label }}</span>
    </RouterLink>

    <div class="languages switch">
      <input id="language-toggle" class="check-toggle check-toggle-round-flat" type="checkbox" :checked="checked"  @change="goToLanguage">
      <label for="language-toggle"></label>
        <RouterLink v-for="(language, index) in languages"
                  :to="`/${language}/${activeType}`" :key="index"
                    active-class="on" class="links off">
          {{ language }}
      </RouterLink>
    </div>
  </div>

</template>

<script>
export default {
  name: "MenuNavigation",
  props: {
    activeLanguage: {
      type: String,
      required: true,
    },
    activeType: {
      type: String,
      required: true,
    }
  },
  data() {
    return {
      languages: ['ro', 'en']
    }
  },
  methods: {
    goToLanguage() {
      console.log('weee');
      if(this.activeLanguage === 'ro') {
        this.$router.push(`/en/${this.activeType}`)
        this.$router.go( '')
      }
      else {
        this.$router.push(`/ro/${this.activeType}`)
        this.$router.go( '')
      }
    }
  },
  computed: {
    checked() {
      return this.activeLanguage === 'ro';
    },
    menus() {
      if (this.activeLanguage === 'ro') {
        return [
          {
            uid: 'food',
            label: 'Meniu Mancare',
          },
          {
            uid: 'drink',
            label: 'Meniu Bautura',
          },
        ];

      }
      return [
        {
          uid: 'food',
          label: 'Food Menu',
        },
        {
          uid: 'drink',
          label: 'Drinks Menu',
        },
      ];
    },
  }
}
</script>

<style lang="scss" scoped>


.menu {
  width: 100%;
  position: fixed;
  padding: 6px 0;
  top: 0;
  background: #004f31;
  z-index: 2;
  font-family: 'Playpen Sans', serif;

  .item {
    height: 20px;
    display: inline-block;
    line-height: 20px;
    padding: 6px;
    margin: 0 3px;
    font-style: normal;
    font-weight: 5s00;
    font-size: 14px;
    color: #fff;
    text-decoration: none;
    border: 1px solid #fff;
    border-radius: 16px;

    > span {
      margin-bottom: -3px;
    }

    &.active {
      background: #FFFFFF;
      color: #004f31;
    }
  }

  .switch {
    position: relative;
    display: inline-block;
  }

  .switch > a {
    position: absolute;
    top: 6px;
    pointer-events: none;
    font-family: 'Roboto', Arial, sans-serif;
    font-weight: bold;
    font-size: 12px;
    text-transform: uppercase;
    text-shadow: 0 1px 0 rgba(0, 0, 0, .06);
    width: 50%;
    text-align: center;
  }

  input.check-toggle-round-flat:checked ~ .off {
    color: #004F31;
  }

  input.check-toggle-round-flat:checked ~ .on {
    color: #FFFFFF;
  }

  .switch > a.off {
    right: 0;
    padding-right: 4px;
    color: #004F31;
  }

  .switch > a.on {
    left: 0;
    padding-left: 2px;
    color: #FFFFFF;
  }



  .check-toggle {
    position: absolute;
    margin-left: -9999px;
    visibility: hidden;
  }

  .check-toggle + label {
    display: block;
    position: relative;
    cursor: pointer;
    outline: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  input.check-toggle-round-flat + label {
    padding: 2px;
    width: 60px;
    height: 20px;
    background-color: #004F31;
    -webkit-border-radius: 60px;
    -moz-border-radius: 60px;
    -ms-border-radius: 60px;
    -o-border-radius: 60px;
    border-radius: 60px;
  }

  input.check-toggle-round-flat + label:before, input.check-toggle-round-flat + label:after {
    display: block;
    position: absolute;
    content: "";
  }

  input.check-toggle-round-flat + label:before {
    top: 2px;
    left: 2px;
    bottom: 2px;
    right: 2px;
    background-color: #FFFFFF;
    -moz-border-radius: 60px;
    -ms-border-radius: 60px;
    -o-border-radius: 60px;
    border-radius: 60px;
  }

  input.check-toggle-round-flat + label:after {
    top: 4px;
    left: 4px;
    bottom: 4px;
    width: 30px;
    background-color: #004f31;
    -webkit-border-radius: 52px;
    -moz-border-radius: 52px;
    -ms-border-radius: 52px;
    -o-border-radius: 52px;
    border-radius: 52px;
    -webkit-transition: margin 0.2s;
    -moz-transition: margin 0.2s;
    -o-transition: margin 0.2s;
    transition: margin 0.2s;
  }

  input.check-toggle-round-flat:checked + label {
  }

  input.check-toggle-round-flat:checked + label:after {
    margin-left: 0px;
  }

  .languages {
    position: fixed;
    right: 0;
    top: 12px;
    font-style: normal;

    > a {
      display: block;
      line-height: 16px;
      padding: 2px;
      margin: 0;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      color: #3A4841;
      text-decoration: none;
      top: 3px;

      img {
        height: 16px;
      }

      &.active {
        background: transparent;
        border-radius: 40px;
        color: #ffffff;

        img {
          height: 8px;
          margin-top: 2px;
        }
      }
    }
  }
}
</style>
