<template>
  <div class="menu-view">
    <div class="loading" v-if="isLoading">
      <div class="lds-roller">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
    <template v-else>
      <div class="navigation" :class="type">
        <TopNavigation :activeType="type" :activeLanguage="language"/>
      </div>
      <SubCategoriesNavigation :items="categories" :activeItem="activeItem" @scrollToItem="onScrollToItemDo"/>
      <div class="category-content">
        <Category :item="item" v-for="item in categories" :key="item.id" @updateActiveMenu="updateActiveMenu"/>
      </div>
    </template>
  </div>
</template>

<script>
import TopNavigation from "../components/Navigation/TopNavigation.vue";
import SubCategoriesNavigation from "../components/Navigation/SubCategoriesNavigation.vue";
import Category from "../components/Category";
import {normalizeMenu} from "@/data/normalize";
import {PRISMIC_LANGUAGES} from "@/constants/language";
import { debounce } from "lodash/function";
import throttle from "lodash/throttle";

const MENU_TYPES = {
  food: 'food-menu',
  drink: 'bar-menu'
};
export default {
  name: 'MenuView',
  components: {
    TopNavigation,
    SubCategoriesNavigation,
    Category
  },
  props: {
    language: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
    }
  },
  computed: {
    activeMenu() {
      return this.menus.find(menu => menu.uid === MENU_TYPES[this.type]);
    },
    categories() {
      return normalizeMenu(this.activeMenu).categories;
    }
  },
  data: function () {
    return {
      isLoading: true,
      error: null,
      menus: [],
      activeItem: null,
      timeout: null,
    };
  },
  methods: {
    scrollIntoView: function (id) {
      if(!id) return;
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
      }
    },
    scrollIntoViewTimeout: function (id) {
      if(!id) return;
      if(this.timeout) {
        clearTimeout(this.timeout);
      }
      this.timeout = setTimeout(() => {
        this.scrollIntoView(id);
      }, 100);
    },
    updateActiveMenu: debounce(function (id) {
        if(!id) return;

        this.activeItem = id;
        history.pushState(null, null, this.$router.currentRoute.path + `#${id}`);

    }, 100),
    onScrollToItemDo: throttle(function (id) {
      if(!id) return;

      this.scrollIntoViewTimeout(id);
    }, 100),
    async loadMenu(lang) {
      const language = PRISMIC_LANGUAGES[this.language] || 'en-gb';
      console.log(language)
      this.isLoading = true;
      const response = await this.$prismic.client.query(
        this.$prismic.predicates.at('document.type', 'menu'),
        {lang: language}
      );
      if (response.results.length === 0) {
        this.error = true;
        return;
      }

      this.menus = response.results;
      this.isLoading = false;
    },
  },
  async created() {
    await this.loadMenu();
  },
  watch: {
    language: {
      handler: async function (language) {
        await this.loadMenu();
      },
      immediate: true,
    }
  }
}
</script>


<style lang="scss">
.menu-view {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height: 100%;
  width: 100%;
  font-family: 'Libra', sans-serif;


  .navigation {
    height: 90vh;
  }

  .navigation.food {
    background: url("./../../public/assets/food.png") no-repeat center center;
    background-size: cover;
  }

  .navigation.drink {
    background: url('./../../public/assets/drink.png') no-repeat center center;;
    background-size: cover;
  }


  .loading {
    width: 100%;
    height: 100vh;
    background: #004f31;

    .lds-roller {
      display: inline-block;
      position: absolute;
      top: calc(50% - 40px);
      left: calc(50% - 40px);
      width: 80px;
      height: 80px;
    }

    .lds-roller div {
      animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
      transform-origin: 40px 40px;
    }

    .lds-roller div:after {
      content: " ";
      display: block;
      position: absolute;
      width: 7px;
      height: 7px;
      border-radius: 50%;
      background: #fff;
      margin: -4px 0 0 -4px;
    }

    .lds-roller div:nth-child(1) {
      animation-delay: -0.036s;
    }

    .lds-roller div:nth-child(1):after {
      top: 63px;
      left: 63px;
    }

    .lds-roller div:nth-child(2) {
      animation-delay: -0.072s;
    }

    .lds-roller div:nth-child(2):after {
      top: 68px;
      left: 56px;
    }

    .lds-roller div:nth-child(3) {
      animation-delay: -0.108s;
    }

    .lds-roller div:nth-child(3):after {
      top: 71px;
      left: 48px;
    }

    .lds-roller div:nth-child(4) {
      animation-delay: -0.144s;
    }

    .lds-roller div:nth-child(4):after {
      top: 72px;
      left: 40px;
    }

    .lds-roller div:nth-child(5) {
      animation-delay: -0.18s;
    }

    .lds-roller div:nth-child(5):after {
      top: 71px;
      left: 32px;
    }

    .lds-roller div:nth-child(6) {
      animation-delay: -0.216s;
    }

    .lds-roller div:nth-child(6):after {
      top: 68px;
      left: 24px;
    }

    .lds-roller div:nth-child(7) {
      animation-delay: -0.252s;
    }

    .lds-roller div:nth-child(7):after {
      top: 63px;
      left: 17px;
    }

    .lds-roller div:nth-child(8) {
      animation-delay: -0.288s;
    }

    .lds-roller div:nth-child(8):after {
      top: 56px;
      left: 12px;
    }

    @keyframes lds-roller {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }

  }
}
</style>
