<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
export default {
  name: 'App',
  watch: {
    $route(to, from) {
      if (!to.hash) {
        window.scroll(0, 0);
      }
    }
  },

}

</script>

<style lang="scss">

body {
  margin: 0;
  padding: 0;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  width: auto;
  height: auto;
  max-width: 560px;
  margin: 0 auto;
  //background: url("../public/assets/background.png") repeat center center;
  background: rgb(243,238,231);
  background: linear-gradient(180deg, rgba(243,238,231,0.6) 0%, rgba(11,157,31,0.05) 50%, rgba(243,238,231,0.6) 100%);
}

@media (min-width: 576px) {
  #app {
    width:576px;
    aspect-ratio: 1.5;
    height: 100%;
  }
}
</style>
