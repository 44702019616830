import { render, staticRenderFns } from "./SubCategoriesNavigation.vue?vue&type=template&id=1ff32d1b&"
import script from "./SubCategoriesNavigation.vue?vue&type=script&lang=js&"
export * from "./SubCategoriesNavigation.vue?vue&type=script&lang=js&"
import style0 from "./SubCategoriesNavigation.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports