/**
 *
 * @param category
 * @return {{name: *, url, products: *}}
 */
const normalizeCategory = (category) => ({
    name: category.primary.category_name,
    id: generateCategoryId(category),
    imageUrl: category.primary.background_image.url,
    products: category.items.map(product => ({
        name: product.name || '',
        price: product.price || '',
        currency: product.currency || '',
        quantity: product.quantity || '',
        quantityUnit: product.quantity_um || '',
        description: product.description || '',
    }))
})

const generateCategoryId = (category) => {
    return category.primary.category_name
        .toLowerCase()
        .replace(/ /g, '-')
        .replace(/[^\w-]+/g, '')
};
/**
 * @param menu
 * @return {{categories: Array<normalizeCategory>}}
 */
export const normalizeMenu = (menu) => {
    // TODO filter nulls and empty categories
    return {
        categories: menu.data?.body?.map(el => normalizeCategory(el))
    }
}
